<template>
  <div>
    <v-data-table
      :headers="atual.header"
      :items="atual.getter"
      hide-default-footer
      no-data-text="Nenhum Fornecedor Cadastrado"
    >
      <template v-slot:[`item.actions`]="{ item }">
        <v-icon
          color="green darken-4"
          class="mr-2"
          @click="
            () => {
              visibleItem(item);
            }
          "
          >mdi-eye</v-icon
        >
        <v-icon
          v-if="component.permission.Editar"
          color="yellow darken-4"
          class="mr-2"
          @click="
            () => {
              edit_item(item);
            }
          "
          >mdi-pencil</v-icon
        >
        <v-icon
          v-if="component.permission.Excluir"
          color="red darken-4"
          class="mr-2"
          @click="
            () => {
              deleteItem(item);
            }
          "
          >mdi-delete</v-icon
        >
      </template>
    </v-data-table>
    <!-- <Edit :component="this" ref="edit" /> -->
    <!-- <AddDialog :out_of_register="false"  /> -->
    <Delete :component="this" />
    <div>
      <v-divider class="mt-6"></v-divider>
      <v-pagination
        class="mb-3"
        v-model="atualCurrent"
        :length="atualTotal"
        color="success"
        :total-visible="10"
      ></v-pagination>
    </div>
  </div>
</template>

<script>
import { headers } from "./script";
// import Edit from "./Edit/";
import Delete from "./Delete_Item/";
// import AddDialog from "../AddDialog/index.vue"
import {
  GET_ALL_PROVIDER_BY_ENTERPRISE,
  DESTROY_PROVIDER,
  ITEM_TO_UPDATE,
  GET_FILTER_PROVIDER,
} from "../../../store/actions/provider";
import { mapGetters } from "vuex";
import { insertCnpjMask } from "../../Utils/global_functions/clean_cnpj_cpf";
export default {
  props: {
    component: Object(),
  },
  components: { /*Edit,*/ Delete /*AddDialog*/ },
  computed: {
    ...mapGetters([
      "getPhisycalProvider",
      "getLegalProvider",
      "getShowDialogRegisterProvider",
      "getNewProviderToAdd",
    ]),
  },

  data: () => ({
    dialog: Boolean(),
    dialog_cancel: Boolean(),
    paginate: 1,
    items: Array(),
    headers,
    atual: {
      header: Array(),
      getter: Array(),
    },

    legalPeople: Object(),
    physicalPeople: Object(),

    pagPhysicalTotal: Number(),
    pagPhysicalCurrent: Number(),

    pagLegalTotal: Number(),
    pagLegalCurrent: Number(),

    atualCurrent: Number(),
    atualTotal: Number(),
  }),

  methods: {
    visibleItem(item) {
      this.$store.dispatch(ITEM_TO_UPDATE, { item: item, justVisible: true });
      this.$store.dispatch("setShowDialogRegisterProvider", true);
    },
    pagPhysical(total, current) {
      this.atualTotal = total;
      this.atualCurrent = current;
    },
    isLegal(isLegal = false) {
      const type = { isPhisical: "isPhisical", isLegal: "isLegal" };
      this.atual.header =
        this.headers[isLegal ? type.isLegal : type.isPhisical];

       if(isLegal) {
          this.atual.getter = this.getLegalProvider
          this.atualTotal = this.pagLegalTotal
          this.atualCurrent = this.pagLegalCurrent
        } else {
          this.atual.getter = this.getPhisycalProvider
          this.atualTotal = this.pagPhysicalTotal
          this.atualCurrent = this.pagPhysicalCurrent
        }
    },

    async GetAllPhysical(page = 1) {
      await this.$store.dispatch(GET_ALL_PROVIDER_BY_ENTERPRISE, {
        type_people: "PhysicalPeople",
        page: page,
        limit: 10,
        paginate: true,
        enterprise_id: this.component.enterpriseId,
      });
    },

    async GetAllLegal(page = 1) {
      await this.$store.dispatch(GET_ALL_PROVIDER_BY_ENTERPRISE, {
        page: page,
        limit: 10,
        paginate: true,
        type_people: "LegalPeople",
        enterprise_id: this.component.enterpriseId,
      });
    },
    edit_item(item) {
      this.$store.dispatch(ITEM_TO_UPDATE, item);
      this.$store.dispatch("setShowDialogRegisterProvider", true);
    },
    contact_edit(item) {
      let contact = Object();
      let auxContact = Object();
      this.$refs.edit.ListContact = [];
      item.contacts.forEach((e) => {
        contact.id = e.id;
        contact.primaryEmail = e.primaryEmail;
        contact.secundaryEmail = e.secundaryEmail;
        contact.phone = e.phone;
        contact.cellPhone = e.cellPhone;
        contact.phoneReference = e.phoneReference;
        auxContact = { ...contact };
        this.$refs.edit.ListContact.push(auxContact);
      });
    },
    address_edit(item) {
      let address = Object();
      let auxAddress = Object();
      this.$refs.edit.ListAddress = [];
      item.address.forEach((e) => {
        address.id = e.id;
        address.street = e.street;
        address.number = e.number;
        address.district = e.district;
        address.complement = e.complement;
        address.city = e.city.name;
        address.state = e.state;
        address.cep = e.cep;
        address.city_id = e.city_id;
        auxAddress = { ...address };
        this.$refs.edit.ListAddress.push(auxAddress);
      });
    },
    bank_edit(item) {
      let banks = Object();
      let auxBank = Object();
      this.$refs.edit.ListBank = [];
      item.banks.forEach((e) => {
        banks.id = e.id;
        banks.bank = e.bank;
        banks.agency = e.agency;
        banks.count = e.count;
        banks.manager = e.manager;
        auxBank = { ...banks };
        this.$refs.edit.ListBank.push(auxBank);
      });
    },
    deleteItem(item) {
      this.dialog_cancel = !this.dialog_cancel;
      this.ObjectToDestroy = { ...item };
      this.ObjectToDestroy.enterprise_id = this.component.enterpriseId
    },
    async confirmDelete() {
      try {
        await this.$store.dispatch(DESTROY_PROVIDER, this.ObjectToDestroy);
      } catch (e) {
        alert(e);
      } finally {
        this.dialog_cancel = !this.dialog_cancel;
      }
    },
    async AutocompleteProviderPhysical(page = 1) {
      if (this.component.search == "") {
        this.GetAllPhysical();
      } else {
        await this.$store.dispatch(GET_FILTER_PROVIDER, {
          type_people: "PhysicalPeople",
          enterprise_id: this.component.enterpriseId,
          search: this.component.searchInput,
          page: page,
          limit: 10,
          paginate: true,
        });
      }
    },

    async AutocompleteProviderLegal(page = 1) {
      if (this.component.search == "") {
        this.GetAllLegal();
      } else {
        await this.$store.dispatch(GET_FILTER_PROVIDER, {
          type_people: "LegalPeople",
          enterprise_id: this.component.enterpriseId,
          search: this.component.searchInput,
          page: page,
          limit: 10,
          paginate: true,
        });
      }
    },
  },


  watch: {
    getNewProviderToAdd: function () {
      this.GetAllPhysical();
      this.GetAllLegal();
    },
    getPhisycalProvider: function (val) {

      if (val.length == 0) {
        this.pagPhysicalCurrent = 1;
        this.pagPhysicalTotal = 1
        if(!this.component.isFormalPeople){ 
          this.pagPhysical(this.pagPhysicalTotal, this.pagPhysicalCurrent);
          this.atual.getter = val
        }
      } else {
        this.pagPhysicalCurrent = val[0].page.current_page;
        this.pagPhysicalTotal = val[0].page.last_page;
        if(!this.component.isFormalPeople){ 
          this.pagPhysical(this.pagPhysicalTotal, this.pagPhysicalCurrent);
          this.atual.getter = val
        }
      }
    },

    getLegalProvider: function (val) {
      if(val.length == 0) {
        this.pagLegalCurrent = 1;
        this.pagLegalTotal = 1
      } else {
        this.pagLegalTotal = val[0].page.last_page;
        this.pagLegalCurrent = val[0].page.current_page;
        if(this.component.isFormalPeople) {   
          insertCnpjMask(val);
          this.atual.getter = val
          this.pagPhysical(this.pagLegalTotal, this.pagLegalCurrent);
        }
      }
    },
    atualCurrent: function (val) {
      if(this.component.search.length >= 2 && this.component.isFormalPeople) {
        this.AutocompleteProviderLegal && this.AutocompleteProviderLegal(val)
      }
      else if(this.component.search.length >= 2 && !this.component.isFormalPeople) {
        this.AutocompleteProviderPhysical && this.AutocompleteProviderPhysical(val)
      }
      else if(this.component.isFormalPeople) {
        this.GetAllLegal && this.GetAllLegal(val);
      } else {
        this.GetAllPhysical && this.GetAllPhysical(val);
      }
    },
    "component.search": function () {
      this.AutocompleteProviderPhysical();
      this.AutocompleteProviderLegal();
    },
    "component.searchInput": function (val) {
      if(val == "") {
        this.component.search = ""
      }
    },

    "component.enterpriseId": function (val) {
        this.GetAllLegal()
        this.GetAllPhysical()
    },
  },
  mounted() {
    this.isLegal();

    this.$watch(
      () => this.component.isFormalPeople,
      function (val) {
        this.isLegal(val);
      }
    );
  },
};
</script>
